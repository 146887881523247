html,
body {
  height: 100%;
  overflow-y: hidden;
}
.cores {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.core_header {
  position: sticky;
  top: 0;
  background-color: white;
  width: 100vw;
  height: 65px;
  object-fit: contain;
  z-index: 1;
  justify-content: center;
}
.core_uurococontener {
  display: flex;
  width: 100vw;
  justify-content: center;
}
.core_uurocologo {
  z-index: 0;
  width: 50px;
  height: 60px;
  background: url(../img/co/uurocoLogo.png) top center / cover no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
  transform: translate(0%, -10%);
}
.core_title {
  margin: 0;
  transform: translate(5%, -10%);
  font-size: 2.5em;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  line-height: 0.95em;
  font-weight: bold;
  color: transparent;
  background-color: transparent;
}
.core_title span {
  background-size: auto auto;
  background-color: rgba(1, 6, 88, 1);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(252, 167, 167, 1) 5px,
    rgba(252, 167, 167, 1) 10px
  );
  -webkit-background-clip: text;
}
.core_logo {
  display: flex;
  width: 100%;
  height: 15vmin;
  justify-content: space-between;
  background: url(../img/co/Coreheader.png) top center / cover no-repeat;
  flex-direction: row;
}
.core_logocont {
  display: flex;
  flex-direction: row;
}

.core_btnChat {
  display: flex;
  width: 10vmin;
  height: 10vmin;
  border: none;
  cursor: pointer;
  outline: none;

  background: url(../img/co/Talkaicon.png) top center / cover no-repeat;
  background-size: cover;
  transform: translate(20%, -40%);
  text-align: end;

  margin-left: 10%;
  margin-right: 20px;
}
.core_btnAvatar {
  display: flex;
  width: 16.5vmin;
  height: 11vmin;
  border: none;
  cursor: pointer;
  outline: none;
  margin-left: 10%;
  margin-right: 20px;
  background: url(../img/co/Avataraicon.png) top center / cover no-repeat;
  transform: translate(20%, -40%);
  text-align: end;
}
.core_setprofilecontener {
  display: flex;
  flex-direction: column;
}
.core_setprofile {
  display: flex;
  max-width: 100vw;
}
.core_btnModal {
  display: flex;
  background-color: transparent;
  color: rgb(245, 239, 239);
  transform: translate(0%, -10%);
  font-size: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: right;
  justify-content: flex-end;
}
.core_btnModal::before {
  content: "";
  width: 70px;
  height: 50px;
  position: absolute;
  border-radius: 0 0 0 100px;
  right: 0px;
  top: 5px;
  background: #ffffff;
}
.core_logout {
  display: flex;
  position: absolute;
  right: 0px;
  top: 30px;
}

.core_login {
  width: 100vw;
  height: auto;
  display: flex;
}
.core_bodys {
  width: 100vw;
  height: 85vh;
  display: flex;
}
.core_image {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 85vh;
  padding: 1em;
  object-fit: contain;
  background: url(../img/co/posthaikei2.png) bottom center / cover no-repeat;
  background-size: cover;
  overflow-y: scroll;
}
.core_setmodels {
  display: flex;
  margin: 30px;
}
.core_posts {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 98%;
}
.core_footer {
  display: flex;
  position: sticky;
  bottom: 0;
  width: 100%;
  max-height: 20vw;
  justify-content: space-between;
  background-size: auto auto;
  background-color: rgba(1, 6, 88, 1);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(252, 167, 167, 1) 5px,
    rgba(252, 167, 167, 1) 10px
  );
}

.core_footercontener {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 18vw;
  justify-content: space-between;
}
.core_hp {
  display: flex;
  font-size: 2vmin;
  width: 20vw;
  align-items: flex-end;
  text-align: center;
}
.core_hp a {
  text-decoration: none;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  margin: 5%;
}
.core_contact {
  display: flex;
  font-size: 2vmin;
  width: 20vw;
  text-align: center;
  align-items: flex-end;
}
.core_contact a {
  text-decoration: none;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  margin: 5%;
}
.core_qqcontener {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}
.core_footertos {
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  justify-content: flex-end;
  border: none;
  cursor: pointer;
  outline: none;
}
.core_footerdescri {
  display: flex;
  flex-direction: column;
  width: 25vw;
  font-size: 1.5vw;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  justify-content: flex-end;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
}
.core_signUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
}
.core_profile {
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
}
.core_textprofile {
  font-size: 30px;
}
.core_nickphoto {
  font-size: 15px;
  color: #010658;
}
.core_basebutton {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.core_errorMesse {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
}
.core_errorText {
  font-size: 15px;
  color: #010658;
}
.core_nickname {
  transform: translate(0%, 0%);
  font-size: 5px;
  color: #ffffff;
}
.core_photo {
  transform: translate(0%, 100%);
  font-size: 5px;
  color: #ffffff;
}
