.chatContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.messageContainer {
  display: flex;
  flex-direction: column;
}
.messageContainer .user {
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
}
.messageContainer .userheader {
  position: relative;
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: justify;
  width: 8vmin;
  height: 4.5vmin;
}
.messageContainer .useravatar {
  z-index: 0;
  position: absolute;
  top: 10px;
  left: 5px;
  width: 6vmin;
  height: 6vmin;
}
.usertext {
  font-size: 2vmin;
  font-weight: lighter;
}
.usercard {
  display: inline-block;
  background-color: #fff;
  border: solid 1px #ddc7f1;
  border-radius: 50px;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: justify;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1.5em;
  padding-left: 1em;
  max-width: 70vw;
  top: 10px;
}
.usercard:before {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #e4a6a4;
  transform: rotate(-1deg);
}
.usercard:after {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #e78a8a;
  transform: rotate(1deg);
}
.usercard p {
  margin: 0;
  padding: 0;
}
.usercardn::before {
  content: "";
  position: absolute;
  display: block;
  width: 80%;
  max-width: 1vmin;
  height: auto;
  max-height: 1vmin;
  border-radius: 0 0 100% 0;
  right: -15px;
  top: -5px;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-right: 5px solid #ffffff;
  border-bottom: 20px solid transparent;
  transform: rotate(-100deg);
}
.messageContainer .bot {
  align-self: flex-start;
  display: flex;
}
.messageContainer .botheader {
  position: relative;
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: justify;
  width: 6vw;
  height: 3vw;
}
.messageContainer .botavatar {
  z-index: 0;
  position: absolute;
  top: 10px;
  left: 50px;
}
.bottext {
  font-size: 2vmin;
  font-weight: lighter;
}
.botcard {
  display: inline-block;
  background-color: #fff;
  border: solid 1px #ddc7f1;
  border-radius: 50px;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: justify;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  padding-left: 1.5em;
  max-width: 70vw;
  top: 10px;
}
.botcard:before {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #baa4e4;
  transform: rotate(-1deg);
}
.botcard:after {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #ab8ae7;
  transform: rotate(1deg);
}
.botcard p {
  margin: 0;
  padding: 0;
}
.botcardn::before {
  content: "";
  position: absolute;
  display: block;
  width: 80%;
  max-width: 1vmin;
  height: auto;
  max-height: 1vmin;
  border-radius: 0 0 100% 0;
  left: -10px;
  top: 25px;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 10px solid transparent;
  transform: scale(1, -1) rotate(100deg);
}
.nickName {
  font-size: 1.5vmin;
}
@keyframes arrowmove {
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(4px);
  }
}
.chats {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80%;
}
.chat_box {
  position: relative;
  margin-top: 5vmin;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat_footer {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  align-items: center;
  width: 80%;
  height: 40%;
}
.chat_inputwrapper {
  display: flex;
  flex-direction: row;
}
.chat_input {
  display: flex;
  position: relative;
  width: 50vmin;
  padding-left: 30px;
  border-radius: 50px;
}
.chat_input input {
  width: 100%;
  height: 3vmax;
  border-radius: 50px;
  border: solid 1px #ffffff;
  box-shadow: 0px 3px 6px #ab8ae7;
  font-size: 16px;
  transform: scale(0.8);
}
.chat_input input:focus {
  outline-style: none;
}
.chat_button {
  display: flex;
  position: relative;
  background: url(../img/co/Sendaicon.png) top center / cover no-repeat;
  background-size: cover;
  width: 25%;
  max-width: 3vw;
  height: auto;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 3vmax;
  transform: translate(-30%, -30%);
}
.chat_playerwrapper {
  transform: translate(0%, 0%);
}
.chat_player {
  position: absolute;
}
