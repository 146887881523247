.post {
  height: auto;
  max-width: 25vh;
  margin: 0;
}
.post_header {
  display: flex;
}
.post_avatar {
  width: 100%;
  max-width: 9vw;
  height: auto;
  max-height: 9vw;
  z-index: 0;
  position: absolute;
  top: 40px;
  right: -15px;
}
.post_text {
  writing-mode: vertical-rl;
  margin-right: 10px;
  font-size: 2vmin;
  font-weight: lighter;
  top: 40px;
}
.post_checkBox {
  width: 60%;
  min-width: 5vw;
  height: auto;
  min-height: 5vw;
  z-index: 0;
  position: absolute;
  top: 20px;
  right: 5px;
}
.post_checkedIcon {
  color: #f1ee0b;
}
.post_avatarGroup {
  width: 80%;
  max-width: 5vw;
  height: auto;
  max-height: 5vw;
  z-index: 0 !important;
  position: absolute;
  bottom: -5px;
  right: -5px;
}
.speech {
  background-color: #fff;
  border: solid 1px #ddc7f1;
  border-radius: 50px;
  display: inline-block;
  top: 40px;
  padding: 2em 1em;
  position: relative;
  max-height: 250px;
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: justify;
}
.speech:before {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #baa4e4;
  transform: rotate(-2deg);
}
.speech:after {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
  border: solid 1px #ab8ae7;
  transform: rotate(2deg);
}
.speech p {
  margin: 0;
  padding: 0;
}
.speechn::before {
  content: "";
  position: absolute;
  display: block;
  width: 80%;
  max-width: 1vmin;
  height: auto;
  max-height: 1vmin;
  border-radius: 100% 0 0 0;
  transform: rotate(5deg);
  left: 5px;
  top: -15px;
  border-left: 5px solid #ffffff;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.nickNamep {
  font-size: 1.5vmin;
}
