.auth_title {
  display: flex;
  text-align: center;
  font-size: 7vw;
  width: 100%;
  height: 30vmin;
  align-items: flex-end;
  justify-content: center;
  color: #010658;
}
.auth_profiletitle {
  display: flex;
  text-align: center;
  font-size: 5vw;
  width: 100%;
  height: 10vmin;
  align-items: flex-end;
  justify-content: center;
  color: #010658;
}
.auth_text {
  color: rgb(6, 153, 211);
  text-align: center;
  cursor: pointer;
}
.auth_signUp {
  display: flex;
  flex-direction: column;
}
.auth_tos {
  display: flex;
  flex-direction: column;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
}
.auth_toshaeder {
  padding: 5px;
}
.auth_toscenter {
  white-space: pre-line;
}
.auth_descri {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
}
.auth_descrihaeder {
  padding: 5px;
}
.auth_descricenter {
  white-space: pre-line;
}

.auth_bodys {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth_samplebeta {
  position: absolute;
  font-size: 3vw;
  left: 10px;
  color: #ffffff;
}
.auth_loginimg {
  display: flex;
  flex-direction: column;
  width: 80vmin;
  height: 67vmin;
  background: url(../img/au/loginpic.png) bottom center / cover no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  outline: none;
}

.auth_secondback {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #f78181;
}
.auth_secondtoptext {
  font-family: "Noto Sans TC", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 7vw;
  color: #010658;
}
.auth_secondcontener1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 85vmin;
  align-items: stretch;
  justify-content: center;
}
.auth_secondtextcontener1 {
  display: flex;
  flex-direction: column;
  color: #010658;
  width: 50vmin;
  height: 80vmin;
  justify-content: space-evenly;
}
.auth_secondtext1 {
  font-family: "YuGothic";
  font-weight: normal;
  text-align: center;
  width: 50vw;
  transform: translate(-95%, 0%);
  font-size: 5vw;
  margin: 2px;
  white-space: pre-line;
}
.auth_secondtext2 {
  font-family: "YuGothic";
  font-weight: normal;
  text-align: center;
  width: 50vw;
  transform: translate(-95%, 0%);
  font-size: 3vw;
  margin: 2px;
  white-space: pre-line;
}
.auth_secondimg1 {
  display: flex;
  flex-direction: column;
  width: 50vmin;
  height: 80vmin;
  background: url(../img/au/chatphoto.png) bottom center / cover no-repeat;
  background-size: cover;
  transform: translate(100%, 0%);
}
.auth_secondcontener2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vmin;
  align-items: stretch;
  justify-content: center;
}
.auth_secondimg2 {
  display: flex;
  flex-direction: column;
  width: 50vmin;
  height: 90vmin;
  background: url(../img/au/postphoto.png) bottom center / cover no-repeat;
  background-size: cover;
  transform: translate(10%, 0%);
}
.auth_secondtext3 {
  display: flex;
  font-family: "YuGothic";
  font-weight: normal;
  text-align: center;
  width: 50vmin;
  height: 70vmin;
  transform: translate(0%, 0%);
  font-size: 3vw;
  margin: 2px;
  white-space: pre-line;
  color: #010658;
  justify-content: center;
  align-items: center;
}
.auth_thridback {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120vmin;
  background-color: #010658;
  justify-content: flex-end;
}
.auth_thridcontener1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110vmin;
}
.auth_thridtextcontener1 {
  display: flex;
  flex-direction: column;
  color: #f78181;
  width: 100%;
  height: 50vmin;
}
.auth_thridtoptext {
  font-family: "YuGothic";
  font-weight: normal;
  width: 90%;
  font-size: 5vw;
  margin: 2px;
  white-space: pre-line;
  transform: translate(10%, 0%);
}

.auth_thridtext {
  font-family: "YuGothic";
  font-weight: normal;
  width: 90%;
  font-size: 3vw;
  margin: 2px;
  white-space: pre-line;
  transform: translate(10%, 10%);
}

.auth_thridcontener2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vmin;
  align-items: stretch;
  justify-content: center;
}
.auth_thridimg1 {
  display: flex;
  flex-direction: column;
  width: 50vmin;
  height: 90vmin;
  background: url(../img/au/profilephoto.png) bottom center / cover no-repeat;
  background-size: cover;
  transform: translate(10%, 0%);
}
.auth_thridimg2 {
  display: flex;
  flex-direction: column;
  width: 50vmin;
  height: 90vmin;
  background: url(../img/au/avatarphoto.png) bottom center / cover no-repeat;
  background-size: cover;
  transform: translate(-10%, 0%);
}
.auth_footer {
  display: flex;
  position: sticky;
  bottom: 0;
  background-size: auto auto;
  background-color: rgba(1, 6, 88, 1);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(252, 167, 167, 1) 5px,
    rgba(252, 167, 167, 1) 10px
  );
  width: 100%;
  height: 15vmin;
  color: #b1afaf;
  justify-content: space-between;
}

.auth_footercontener {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15vmin;
  justify-content: space-between;
}
.auth_hp {
  display: flex;
  font-size: 2vmin;
  width: 20vw;
  align-items: flex-end;
  text-align: center;
}
.auth_hp a {
  text-decoration: none;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  margin: 5%;
}
.auth_contact {
  display: flex;
  font-size: 2vmin;
  width: 20vw;
  text-align: center;
  align-items: flex-end;
}
.auth_contact a {
  text-decoration: none;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  margin: 5%;
}
.auth_qqcontener {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-right: 5px;
}
.auth_footertos {
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  justify-content: flex-end;
  border: none;
  cursor: pointer;
  outline: none;
}
.auth_footerdescri {
  display: flex;
  flex-direction: column;
  width: 25vw;
  font-size: 1.5vw;
  background-color: #f78181;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  justify-content: flex-end;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
}
