html,
body {
  display: flex;
  width: 100%;
  height: 100%;
}

.avatar_change {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sliderContainer {
  width: 90%;
  margin-top: 5%;
}
.avatar_changegroup1 {
  display: flex;
  flex-direction: row;
  transform: translate(20px, 0%);
}
.avatar_changegroup2 {
  display: flex;
  transform: translate(20px, 0%);
}
.avatar_changegroup3 {
  display: flex;
  transform: translate(20px, 0%);
}
.avatar_basic0 {
  background: #000000;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: 10px;
}
.avatar_basic1 {
  background: #fcf7f3;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: 10px;
}
.avatar_first {
  background: #fcf7f3;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 10px;
  border: solid 5px #fcf7f3;
}
.avatar_second {
  background: #000000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 10px;
  border: solid 5px #000000;
  color: #ffffff;
}
.avatar_stage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80vmin;
  font-size: 10px;
}
.avatar_setmodels {
  display: flex;
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  img {
    width: 100%;
    height: auto;
  }
}
.avatar_texts {
  display: flex;
  white-space: pre-wrap;
  background-color: #ffffff;
  width: 45%;
  height: 70%;
  margin-top: 20px;
  font-size: 2vmin;
  flex-direction: column;
  align-items: center;
}
.avatar_texts a {
  text-decoration: none;
  background-color: #f78181;
  font-size: 2vmin;
  color: #010658;
  border-radius: 50px;
  padding: 5%;
  margin: 5%;
}
.avatar_button {
  background-color: #ffffff;
  width: 6vmax;
  height: 6vmax;
  border-radius: 100%;
  border: solid 1px #baa4e4;
  position: absolute;
  top: 350px;
  right: 90px;
}
.avatar_button:before {
  position: absolute;
  top: 2px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 2px;
  border: solid 1px #baa4e4;
}
.avatar_button:after {
  position: absolute;
  top: -2px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: -2px;
  border: solid 1px #ab8ae7;
}
.avatar_error {
  color: #4c00ff;
  text-align: center;
  margin: 10px;
}
